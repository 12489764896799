import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

function PageNotFound(props) {

  const {t} = useTranslation()

  return <Box sx={{padding: 6, paddingTop: 12}}>
    <Typography sx={{fontSize: '8rem', fontWeight: 'bold'}} component="center"> {t("Coming Soon")} </Typography>
    <Typography component="center" sx={{p: 1}}> {t("Uh Oh! The page you were looking for is under construction.")} </Typography>
  </Box>
}


export default PageNotFound