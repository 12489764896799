import React from 'react'
import {IconButton, Tooltip} from '@mui/material'
import PropTypes from 'prop-types'

function IconWithToolTip(props) {
  return (
    <Tooltip title={props.title}>
      <span>
      <IconButton onClick={props.onClick} size={props.size} disabled={props.disabled}>
        {props.icon}
      </IconButton>
      </span>
    </Tooltip>
  );
}

IconWithToolTip.defaultProps = {
  size: 'large'
}

IconWithToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string
}

export default IconWithToolTip