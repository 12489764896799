import PriceChangeOutlined from '@mui/icons-material/PriceChangeOutlined'
import {lazy} from 'react'
import { Route } from 'react-router-dom'
import AttachMoneyOutlined from '@mui/icons-material/AttachMoneyOutlined'


const CoopangPrice = lazy(() => import('pages/coopang-price'))
const CoopangPriceChange = lazy(() => import('pages/coopang-price-change'))


const routes = [
  {
    subheader: "",
    items: [
      {
        label: "Coopang Price",
        href: "/coopang-price",
        component: CoopangPrice,
        icon: <AttachMoneyOutlined />
      },
      {
        label: "Price Change Report",
        href: "/price-change-report",
        component: CoopangPriceChange,
        icon: <PriceChangeOutlined />
      }
    ]
  }
]

function getDomRoutes() {
  const domRoutes = [] 
  routes.forEach(group => {
    if(!group.items) {
      return
    }
    group.items.forEach(item => {
      if(!item.component) {
        return
      }
      domRoutes.push(<Route path={item.href} key={item.href} Component={item.component}/>)
    })
  })
  return domRoutes
}

export {getDomRoutes}
export default routes