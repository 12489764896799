import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux"
import { logout } from "store/user"


function Logout(){

  const {t} = useTranslation();

  const dispatch = useDispatch()

  dispatch(logout())

  return <div>{t("Logging out")}</div>
}


export default Logout