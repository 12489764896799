import Menu from "@mui/icons-material/Menu"
import { AppBar, Badge, Box, Button, CircularProgress, Typography, useMediaQuery } from "@mui/material"
import IconWithToolTip from "components/IconWithToolTip"
import { useGetRequest } from "components/useNetworkRequest"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, useNavigate } from "react-router-dom"
import { setMenuOpen } from "store/Session"
import { logout, setUserDetails } from "store/user"
import { currencyFormat } from "utils/formatters"
const Chat = React.lazy(() => import('pages/chat'))
const Deposit = React.lazy(() => import('pages/deposit'))
const Account = React.lazy(() => import('pages/account'))

const routes = [
  {
    label: "Chat",
    path: "/chat",
    component: Chat,
    render: (user, label) => <Badge badgeContent={user?.hasUnreadMessage === true ? 1 : 0} color="secondary" variant='dot'> {label} </Badge>
  },
  {
    label: "Deposit",
    path: "/deposit",
    component: Deposit
  },
  {
    label: "My Profile",
    path: "/account",
    component: Account
  }
]

function headerRoutes() {
  let domRoutes = []
  
  routes.forEach(route => {
    if(route.component) {
      domRoutes.push(<Route  path={route.path} key={route.path} Component={route.component}/>)
    }
  })
  return domRoutes
}

export  {headerRoutes}

export default function() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  const {t} = useTranslation()
  const user = useSelector(state => state.user.details)

  const {response} = useGetRequest({endPoint: '/v1/users', sendOnAttach: true})

  React.useEffect(() => {
    if(response) {
      dispatch(setUserDetails({user: response}))
    }
  }, [response])

  console.log(user)

  return <AppBar sx={{
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '8px 16px',
    }} position="sticky">
    <Typography>
      {!desktop && <IconWithToolTip title={'Open Menu'} onClick={() => dispatch(setMenuOpen(true))} icon={<Menu />}/>}
    </Typography>
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Typography variant="body1" sx={{display: 'flex', mr:2, alignItems: 'flex-end', fontWeight: 500}}> {t("currency")} &nbsp;{user ? currencyFormat(user.points) : <CircularProgress color="secondary" size={24}/>} </Typography>
      {
        routes.map(route => <Button key={route.path} onClick={() => navigate(route.path)} sx={{mr: 2}}> 
          {route.render ? route.render(user, t(route.label)): t(route.label)}
        </Button>)
      }
      <Button onClick={() => dispatch(logout())}> {t("Logout")} </Button>
    </Box>
  </AppBar>
}