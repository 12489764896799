import { createSlice } from '@reduxjs/toolkit'

const initialState = {cartDrawer: false, menuDrawer: false}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCartDrawer(state, action) {
      state.cartDrawer = action.payload
    },
    setMenuOpen(state, action) {
      state.menuDrawer = action.payload
    }
  }
})

export const { setCartDrawer, setMenuOpen } = sessionSlice.actions
export default sessionSlice.reducer
