import { createSlice } from '@reduxjs/toolkit'

const initialState = {authenticated: false, details: {}, accessToken: ''}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, {payload}) {
      state.details = payload.user
      state.authenticated = true
      state.accessToken = payload.tokens.access.token
    },
    logout(state) {
      console.log(initialState)
      state.authenticated = false
      state.details = {}
      state.accessToken = ''
    },
    setUserDetails(state, {payload}) {
      state.details = payload.user
    }
  }
})

export const { setUser, logout, setUserDetails } = userSlice.actions
export default userSlice.reducer
