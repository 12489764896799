

export const currencyFormat = (amount) => {
  if(!isNaN(amount)) {
    return Intl.NumberFormat().format(amount)
  }
  if(!amount) {
    return ''
  }

  if(isNaN(amount)) {
    console.error(amount + ' is not a number')
    return ''
  }

  return Intl.NumberFormat().format(amount)
}