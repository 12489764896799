import Lock from "@mui/icons-material/Lock"
import { LoadingButton } from "@mui/lab"
import { Box, Grid, TextField, Typography } from "@mui/material"
import { usePostRequest } from "components/useNetworkRequest"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setUser } from "store/user"

function RegisterForm() {

  const {inFlight, sendRequest} = usePostRequest({endPoint: '/v1/auth/register'})
  const [data, setData] = useState({email:'', password: ''}) 
  const authenticated = useSelector(state => state.user.authenticated)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if(authenticated) {
      navigate('/home')
    }
  }, [authenticated])

  const setField = e => {
    setData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value 
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await sendRequest(data)
    if(!response || !response.user) {
      return
    }
    dispatch(setUser(response))
    navigate('/home')
  }

  const {t} = useTranslation()

  return <Box onSubmit={handleSubmit} component="form" sx={{display: 'flex', flexDirection: 'column', minWidth: 300, maxWidth: 300, '&>*': {marginBottom: '16px !important'}}}>
    <Typography variant="h5" fontWeight={'bold'} sx={{marginBottom: 0}}> {t("Hi, Welcome to")} {t("ONOFF MARKETING")} </Typography>
    <Typography variant="caption" sx={{marginBottom: 3}} color={'textSecondary'}> {t("Register now to track you site ranking and improve it")} </Typography>
    <TextField onChange={setField} variant="filled" label={t("Name")} required name="name" fullWidth/>
    <TextField onChange={setField} variant="filled" label={t("Email")} required name="email" type="email" fullWidth/>
    <TextField onChange={setField} variant="filled" label={t("Nick Name")} required name="nickname" fullWidth/>
    <TextField onChange={setField} variant="filled" label={t("Password")} required name="password" type='password' fullWidth/>
    <TextField onChange={setField} variant="filled" label={t("Phone")} required name="phone" fullWidth/>
    <LoadingButton type="submit" loading={inFlight} fullWidth variant="contained" endIcon={<Lock />}> {t("Register")} </LoadingButton>
  </Box>
}

function Register(props) {
  return <Grid container sx={{height: '100vh'}}>
    <Grid item sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} xs={12} md={4}>
      <RegisterForm />    
    </Grid>
    <Grid item sx={{height: '100%', width:'100%', backgroundColor: 'white', display: {xs: 'none', md: 'flex',},alignItems:'center',justifyContent:'center'}} xs={12} md={8}>
      <img src="https://i.ibb.co/BLVBJzG/icrm-frontwall.png" alt="001-2" border="0" style={{'width':'65vw'}}/>
    </Grid>
  </Grid>
}

export default Register