import { Box, Divider, Drawer, Grid, ThemeProvider, Typography, createTheme, useMediaQuery } from "@mui/material"
import SideNav from "../components/SideNav"
import routes from "./routes"
import { purple } from "@mui/material/colors"
import styled from "@emotion/styled"
import { useDispatch, useSelector } from "react-redux"
import { setMenuOpen } from "store/Session"
import { useTranslation } from "react-i18next"

const theme = createTheme({
  palette: {
    mode: 'dark', 
    secondary: purple}, 
    typography: {fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(',')
  }
})

const drawerWidth = 240

const StyledGrid = styled(Grid)(() => ({
  backgroundColor: '#1c2536',
  height: '100vh',
  position: 'relative',
  overflow: 'auto',
  width: drawerWidth,
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}))

export default function SideDrawer() {

  const desktop = useMediaQuery(theme => theme.breakpoints.up('md'))
  const menuOpen = useSelector(state => state.session.menuDrawer)
  const dispatch = useDispatch()
  const {t} = useTranslation()

  return <ThemeProvider theme={theme}>
    <Box sx={{width: { md: drawerWidth }, flexShrink: { md: 0 }}}>
    <Drawer anchor="left" variant={desktop ? "permanent" : "temporary"} 
      open={menuOpen}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={() => dispatch(setMenuOpen(false))}
    >
      <StyledGrid>
        <Typography sx={{p:'10px 16px'}} variant="h6" textTransform={'uppercase'} fontWeight={'bold'} letterSpacing={2}> {t("ONOFF MARKETING")} </Typography>
        <Divider />
        <SideNav items={routes}/>
      </StyledGrid>
    </Drawer>
    </Box>
  </ThemeProvider>
}