// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationsEng from './locales/en/translations-en.json';
import translationsKO from './locales/ko/translations-ko.json';

i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: "ko",
  debug: true,
  resources: {
    en: {
      translation: translationsEng,
    },
    ko: {
        translation: translationsKO,
    },
    // Add more languages and translation files as needed.
  },
});

export default i18n;
