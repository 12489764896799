import Lock from "@mui/icons-material/Lock"
import { LoadingButton } from "@mui/lab"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import ImageCarousel from "components/SlickCoruselComp"
import { usePostRequest } from "components/useNetworkRequest"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { setUser } from "store/user"

function LoginForm() {

  const {inFlight, sendRequest} = usePostRequest({endPoint: '/v1/auth/login'})
  const [data, setData] = useState({email:'', password: ''}) 
  const authenticated = useSelector(state => state.user.authenticated)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {t} = useTranslation()

  useEffect(() => {
    if(authenticated) {
      navigate('/')
    }
  }, [authenticated])

  const setField = e => {
    setData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value 
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await sendRequest(data)
    if(!response || !response.user) {
      return
    }
    dispatch(setUser(response))
    navigate('/')
  }


  return <Box onSubmit={handleSubmit} component="form" sx={{display: 'flex', flexDirection: 'column', minWidth: 300, maxWidth: 300, '&>*': {marginBottom: '16px !important'}}}>
    <Typography variant="h5" fontWeight={'bold'} sx={{marginBottom: 0}}> {t("Hi, Welcome to")} {t("ONOFF MARKETING")} </Typography>
    <Typography variant="caption" sx={{marginBottom: 3}} color={'textSecondary'}> {t("Login now to track you site ranking and improve it")} </Typography>
    <TextField onChange={setField} variant="filled" label={t("Email")} required name="email" fullWidth/>
    <TextField onChange={setField} variant="filled" label={t("Password")} required name="password" type='password' fullWidth/>
    <LoadingButton type="submit" loading={inFlight} fullWidth variant="contained" endIcon={<Lock />}> {t("Login")} </LoadingButton>
    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <Typography variant="body2">{t("No account?")}</Typography>
      <Button component={Link} to="/register"> {t("Sign Up")} </Button>
    </Box>
  </Box>
}

const images = [
  'https://i.ibb.co/C9bNBMj/001.png',
  'https://i.ibb.co/WGtMj9t/002.png',
  'https://i.ibb.co/zP0qc3K/003.png',
  'https://i.ibb.co/nDzG9P6/004.png'
];

function Login(props) {
  return <Grid container sx={{height: '100vh'}}>
    <Grid item sx={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} xs={12} md={4}>
      <LoginForm />    
    </Grid>
    <Grid item sx={{height: '100%', width:'100%', backgroundColor: 'white', display: {xs: 'none', md: 'flex',},alignItems:'center',justifyContent:'center'}} xs={12} md={8}>
      <img src="https://i.ibb.co/BLVBJzG/icrm-frontwall.png" alt="001-2" border="0" style={{'width':'65vw'}}/>
      {/* <ImageCarousel imageURLs={images} /> */}
    </Grid>
  </Grid>
}

export default Login